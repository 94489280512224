import React, { useEffect, useMemo } from 'react';
import { Amplify } from '@aws-amplify/core'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import PasswordDialog from "common/src/components/user/Password"
import CypressHistorySupport from "common/src/components/CypressHistorySupport"
import { CrossPlatformProvider } from "common/src/cross-platform/Provider"
import GlobalOverlay from "common/src/components/dialog/Overlay"
import AppDomHelper from "common/src/components/AppDomHelper"
import Loader from "common/src/components/Loader"
import Alert from "common/src/components/dialog/Alert"
import Footer from "common/src/components/admin-layout/Footer"
import SigninHeader from "common/src/components/admin-layout/SigninHeader"

import TopMenu from "./components/TopMenu"
import PageProfile from "./components/pages/Profile"
import PageContacts from "./components/pages/Contacts"
import PageHome from "./components/pages/Home"
import PageSignin from "./components/pages/SignIn"
import PageRegister from "./components/pages/SignUp"
import PagePeople from "./components/pages/People"
import PageInvitations from "./components/pages/Invitations"
import PagePSEApplications from "./components/pages/PSEApplications"
import PageVisitorApplications from "./components/pages/VisitorApplications"
import PageTags from "./components/pages/Tags"

import PageSearchTest from "./components/pages/catalogue/SearchTest"
import PageControlPanel from "./components/pages/catalogue/ControlPanel"
import PageQueryStats from "./components/pages/catalogue/QueryStats"
import PageCommissions from './components/pages/reports/Commissions'
import PageClicks from './components/pages/reports/Clicks'
import PageFris from './components/pages/reports/FRI'
import PageLinks from "./components/pages/reports/Links"
import PageRawReport from "./components/pages/reports/Raw"
import PagePseReport from "./components/pages/reports/PSE"
import PagePseAggReport from "./components/pages/reports/PSEAggregation"

import PageAccCommissions from "./components/pages/accounting/Commissions"
import PageAccSettings from "./components/pages/accounting/PaymentSettings"
import PageAccPayments from "./components/pages/accounting/Payments"

import PageChats from "./components/pages/tools/Chats"
import PageConnections from "./components/pages/tools/Connections"
import PageRevolut from "./components/pages/tools/Revolut"
import PageTiming from "./components/pages/tools/Timing"
import PageRemoveBgBalance from "./components/pages/tools/RemovebgBalance"

import PageBanners from './components/pages/marketing/Banners';

import awsconfig from 'common/src/awsConfig'
import * as userActions from "common/src/actions/user"
import routes from "./routes"
import commonListeners from "common/src/listeners"
import catalogueListeners from "common/src/listeners/catalogue"
import provideComponent from "common/src/cross-platform/provideComponent"
import listeners from "./listeners"
import logger from "common/src/logger"
import user from "common/src/user"
import api from "app/api"
import hub from "common/src/hub"


hub.registerProvider(provideComponent);
Amplify.configure({ ...awsconfig, Analytics: { disabled: true } });
logger.init("/" + process.env.REACT_APP_ENV + "/tf-admin/app");
commonListeners.init();
listeners.init();
catalogueListeners.init();
api.init();


const pages = [
    {
        route: routes.home,
        component: PageHome,
        accessKey: "admin/dashboard"
    },
    {
        route: routes.profile,
        component: PageProfile
    },
    {
        route: routes.profileContacts,
        component: PageContacts
    },
    {
        route: routes.people,
        component: PagePeople,
        accessKey: "admin/people"
    },
    {
        route: routes.invitations,
        component: PageInvitations,
        accessKey: "admin/people"
    },
    {
        route: routes.pseapplications,
        component: PagePSEApplications,
        accessKey: "admin/people"
    },
    {
        route: routes.visitorapplications,
        component: PageVisitorApplications,
        accessKey: "admin/people"
    },
    {
        route: routes.tags,
        component: PageTags,
        accessKey: "admin/tags"
    },


    {
        route: routes.catalogueSearch,
        component: PageSearchTest,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueControl,
        component: PageControlPanel,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueQueryStats,
        component: PageQueryStats,
        accessKey: "admin/catalogue"
    },


    {
        route: routes.reportCommissions,
        component: PageCommissions,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportClicks,
        component: PageClicks,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportFris,
        component: PageFris,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportLinks,
        component: PageLinks,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportRaw,
        component: PageRawReport,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportPse,
        component: PagePseReport,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportPseAgg,
        component: PagePseAggReport,
        accessKey: "admin/analytics"
    },

    {
        route: routes.accountingCommissions,
        component: PageAccCommissions,
        accessKey: "admin/accounting"
    },
    {
        route: routes.accountingSettings,
        component: PageAccSettings,
        accessKey: "admin/accounting"
    },
    {
        route: routes.accountingPayments,
        component: PageAccPayments,
        accessKey: "admin/accounting"
    },

    {
        route: routes.toolsChats,
        component: PageChats,
        accessKey: "admin/tools/chats"
    },
    {
        route: routes.toolsConnections,
        component: PageConnections,
        accessKey: "admin/tools/connections"
    },
    {
        route: routes.toolsRevolut,
        component: PageRevolut,
        accessKey: "admin/tools/revolut"
    },
    {
        route: routes.toolsTiming,
        component: PageTiming,
        accessKey: "admin/tools/timing"
    },
    {
        route: routes.toolsRemovebg,
        component: PageRemoveBgBalance,
        accessKey: "admin/tools/removebg"
    },
    {
        route: routes.marketingBanners,
        component: PageBanners,
    }
];



const nliPages = [
    {
        route: routes.signin,
        component: PageSignin
    },
    {
        route: routes.register,
        component: PageRegister
    },
];


function App() {

    const [current, loggedIn, checking] = useSelector(s => ([
        s.user.current,
        s.user.loggedIn,
        s.user.ui.checking
    ]));
    const queryClient = useMemo(() => new QueryClient(), []);
    const defaultRoute = useMemo(
        () => (loggedIn && current) && pages.find(p => !p.accessKey || user.hasAccessTo(p.accessKey)),
        [loggedIn, current]
    );

    useEffect(
        () => {
            userActions.checkCurrent().catch(err => console.log(err));
        },
        []
    );

    if (loggedIn === null || (loggedIn === true && !current)) {
        return (
            <div className="app-loading"><Loader size={64} /></div>
        )
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <CypressHistorySupport />
                <AppDomHelper />
                <CrossPlatformProvider>
                    {checking ?
                        <div className="app-loading"><Loader size={64} /></div> :
                        loggedIn ?
                            <>
                                <TopMenu />
                                <Switch>

                                    {pages.map((p, inx) => {
                                        if (!p.accessKey || user.hasAccessTo(p.accessKey)) {
                                            return <Route
                                                key={inx}
                                                exact
                                                path={p.route}
                                                component={p.component} />
                                        }
                                        return null;
                                    })}

                                    {defaultRoute && <Redirect from="*" to={defaultRoute.route} />}
                                </Switch>
                            </> :
                            <>
                                <SigninHeader />
                                <Switch>
                                    {nliPages.map((p, inx) =>
                                        <Route
                                            key={inx}
                                            exact
                                            path={p.route}
                                            component={p.component} />
                                    )}
                                    <Redirect from="*" to={routes.signin} />
                                </Switch>
                            </>
                    }
                </CrossPlatformProvider>
                <Footer />
                <PasswordDialog />
                <GlobalOverlay />
                <Alert />
            </Router>
        </QueryClientProvider>
    );
}

export default App