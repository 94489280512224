import useQuery from "common/src/refactor/hooks/useQuery"
import api from "common/src/api"
import { useCallback } from "react";
import Table from "common/src/components/table/Table";
import moment from "moment";
import dateFormats from "common/src/lib/date/formats";

function params2string(params) {
    const list = [];
    for (const key in params) {
        list.push(`${key}: ${params[key]}`);
    }
    return list.join(", ");
}

const queryColumns = [
    {
        id: "params",
        name: "Query",
        render: (row) => params2string(row.params)
    },
    {
        id: "use_count",
        name: "Use count"
    },
    {
        id: "last_used_at",
        name: "Last use",
        render: (row) => moment(row.last_used_at).format(dateFormats.dateTime)
    },
];

const queryDateColumns = [
    {
        id: "date",
        name: "Date",
        render: (row) => moment(row.date).format(dateFormats.date)
    },
    {
        id: "use_count",
        name: "Use count"
    },
];

const queryLogColumns = [
    {
        id: "used_at",
        name: "Timestamp",
        render: (row) => moment(row.used_at).format(dateFormats.dateTime)
    },
    {
        id: "execution_time",
        name: "Execution time",
        render: (row) => row.execution_time + "s"
    },
    {
        id: "partitions",
        name: "Partitions",
        render: (row) => row.partitions.join(", ")
    }
]

async function loadQueries() {
    const { queries } = await api.backend.get("/catalogue/queries");
    return { data: queries };
}

async function loadQueryDates({ queryId }) {
    const { dates } = await api.backend.post("/catalogue/query/dates", { body: { queryId } });
    return { data: dates };
}

async function loadQueryLog({ queryId }) {
    const { log } = await api.backend.post("/catalogue/query/log", { body: { queryId } });
    return { data: log };
}

function QueryDetails({ stats }) {

    const { data: dates } = useQuery(
        loadQueryDates,
        [stats.id],
        {
            params: {
                queryId: stats.id
            }
        }
    );

    const { data: log } = useQuery(
        loadQueryLog,
        [stats.id],
        {
            params: {
                queryId: stats.id
            }
        }
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <div>{params2string(stats.params)}</div>
            <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "flex-start" }}>
                {dates.length > 0 &&
                    <Table
                        cols={queryDateColumns}
                        rows={dates}
                        expandKey="date"
                        variant="slim" />}
                {log.length > 0 &&
                    <Table
                        cols={queryLogColumns}
                        rows={log}
                        expandKey="id"
                        variant="slim" />}
            </div>
        </div>
    )
}

export default function QueryStats() {

    const { data: queries } = useQuery(
        loadQueries,
        [],
        {

        }
    );

    const renderQueryDetails = useCallback(
        (row) => {
            return (
                <QueryDetails stats={row} />
            )
        },
        []
    );

    return (
        <div className="page page-catalogue-control-panel">
            <div className="page-catalogue-control-panel-section" style={{
                width: "100%",
                gridColumnStart: 1, gridColumnEnd: 3, display: "flex",
                flexDirection: "column", gap: "0.5rem", justifyContent: "flex-start"
            }}>

                <h2>Top queries</h2>
                <Table
                    cols={queryColumns}
                    rows={queries}
                    expandKey="id"
                    expandable
                    renderDetails={renderQueryDetails}
                    variant="slim"
                // getRowClass={getFileRowClassName}
                // getCellClassName={getFileCellClassName} 
                />
            </div>

        </div>
    )
}