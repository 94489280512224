import {
    Fragment,
    useCallback,
    useState,
    useMemo,
} from "react";


export default function CollapsibleText({
    text,
    maxLength = -1,
    toggleOnce = false,
    moreLabel = "more",
    lessLabel = "less",
    renderer,
}) {
    const [expanded, setExpanded] = useState(false);

    const enabled =
        maxLength > 0 && text.length > maxLength && (!toggleOnce || !expanded);

    const rendered = useMemo(() => {
        if (!enabled || expanded) {
            return renderer ? renderer(text) : text;
        }

        return renderer
            ? renderer(text.slice(0, maxLength))
            : text.slice(0, maxLength);
    }, [text, maxLength, expanded, renderer, enabled]);

    const onToggle = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setExpanded((prev) => !prev);
    }, []);

    return (
        <Fragment>
            {rendered}
            {enabled && (
                <Fragment>
                    {expanded ? "" : "..."}
                    <a
                        href="/#"
                        className="text-muted-foreground ml-[0.5rem] underline text-sm"
                        onClick={onToggle}>
                        {expanded ? lessLabel : moreLabel}
                    </a>
                </Fragment>
            )}
        </Fragment>
    );
}
