import { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";

import { Button, TextField, Menu, MenuItem } from "@mui/material";

import Table from "common/src/components/table/Table";
import Loader from "common/src/components/Loader";
import PSEApplicationDialog from "app/components/dialog/PSEApplication";
import { alert } from "common/src/components/dialog/Alert";

import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg";
import { ReactComponent as IconEdit } from "common/src/svg/edit.svg";
import { ReactComponent as IconDownload } from "common/src/svg/download.svg";

import api from "app/api";
import useDictRef from "common/src/hooks/useDictRef";
import df from "common/src/lib/date/formats";
import { ui as ddUi } from "common/src/store/dialogs";
import NullForm from "common/src/components/NullForm";
import { data2csv, downloadCsv } from "common/src/lib/csv";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";

function parseLinkedinUrl(value) {
    let url, handle;

    if (value.indexOf("/") === -1) {
        handle = value;
        url = `https://linkedin.com/in/${value}`;
    } else {
        url = value;
        let match = value.match(/in\/([^/]+)/i);
        if (match) {
            handle = match[1];
        } else {
            match = value.match(/^www\.linkedin\.com\/([^/]+)/i);

            if (match) {
                handle = match[1];
                url = `https://linkedin.com/in/${match[1]}`;
            } else {
                handle = value;
                url = `https://linkedin.com/in/${value}`;
            }
        }
    }

    return { url, handle };
}

function prepareWhere({ query }) {
    const where = {};

    if (query) {
        where._or = [
            {
                email: { _ilike: `%${query}%` },
            },
            {
                givenName: { _ilike: `%${query}%` },
            },
            {
                familyName: { _ilike: `%${query}%` },
            },
        ];
    }

    return where;
}

async function retrieveAll(query) {
    const where = prepareWhere({ query });
    const order = {
        createdAt: "desc",
    };
    let apps = [];
    let offset = 0;
    const limit = 1000;

    while (true) {
        const list = await api.pseApplication.list({
            where,
            order,
            offset,
            limit,
        });

        offset += limit;

        apps = [...apps, ...list];

        if (list.length < limit) {
            break;
        }
    }

    return apps;
}

function PSEApplications() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState([]);
    const [list, setList] = useState([]);
    const [query, setQuery] = useState("");
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const [editApplication, setEditApplication] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const ref = useDictRef({
        query,
        list,
        processing,
        currentId,
    });

    const refreshCls = useMemo(
        () => ["icon", "icon-svg-fill", loading ? "spinning" : ""].join(" "),
        [loading]
    );

    const loadApplications = useCallback(
        async () => {
            setLoading(true);
            const where = prepareWhere({ query: ref.query });
            const order = {
                createdAt: "desc",
            };

            const list = await api.pseApplication.list({ where, order });
            setList(list);
            setLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const onActionClick = useCallback((e, id) => {
        setMenuAnchor(e.target);
        setCurrentId(id);
    }, []);

    const onMenuClose = useCallback(() => {
        setMenuAnchor(null);
        setCurrentId(null);
    }, []);

    const toggleAccepted = useCallback(
        async (
            appId,
            accepted = true,
            autoCreate = true,
            applyAccess = false
        ) => {
            if (applyAccess === true) {
                const app = ref.list.find((a) => a.id === appId);
                if (app.settings === null) {
                    alert({ message: "Please edit access first" });
                    return;
                }
            }

            let processing = [...ref.processing];
            processing.push(appId);
            setProcessing(processing);

            const payload = {
                accepted: accepted,
                autoCreateUser: autoCreate,
                acceptedAt: new Date().toISOString(),
            };
            if (applyAccess === false) {
                payload.settings = null;
            }

            await api.pseApplication.update(appId, payload);

            loadApplications();

            processing = [...ref.processing];
            let inx = processing.indexOf(appId);
            processing.splice(inx, 1);
            setProcessing(processing);
        },
        // eslint-disable-next-line
        []
    );

    const onAcceptClick = useCallback(
        async () => {
            toggleAccepted(ref.currentId, true, true, true);
            onMenuClose();
        },
        // eslint-disable-next-line
        []
    );
    const onAcceptClickAuto = useCallback(
        async () => {
            toggleAccepted(ref.currentId, true, true, false);
            onMenuClose();
        },
        // eslint-disable-next-line
        []
    );
    const onDeclineClick = useCallback(
        async () => {
            toggleAccepted(ref.currentId, false, true);
            onMenuClose();
        },
        // eslint-disable-next-line
        []
    );
    const onAcceptNoUserClick = useCallback(
        async () => {
            toggleAccepted(ref.currentId, true, false);
            onMenuClose();
        },
        // eslint-disable-next-line
        []
    );

    const onEditClick = useCallback(
        () => {
            const app = list.find((a) => a.id === ref.currentId);
            setEditApplication(app);
            dispatch(ddUi.show("dialog-pse-application"));
            onMenuClose();
        },
        // eslint-disable-next-line
        [list, currentId]
    );

    const onEdited = useCallback(
        () => {
            loadApplications();
        },
        // eslint-disable-next-line
        []
    );

    const onEditorClosed = useCallback(() => {
        setEditApplication(null);
    }, []);

    const cols = useMemo(
        () => {
            return [
                {
                    id: "name",
                    name: "Name",
                    className: "grid-nowrap",
                    render: (app) => app.givenName + " " + app.familyName,
                },
                { id: "email", name: "Email", className: "grid-nowrap" },
                {
                    id: "phone",
                    name: "Phone",
                    render: (app) => (app.phone ? "+" + app.phone : ""),
                },
                {
                    id: "createdAt",
                    name: "Submitted",
                    className: "grid-nowrap",
                    render: (app) => moment(app.createdAt).format(df.date),
                },
                { id: "city", name: "City" },
                { id: "company", name: "Company" },
                {
                    id: "instagram",
                    name: "Instagram",
                    render: (app) => {
                        if (app.instagram) {
                            return (
                                <a
                                    href={`https://instagram.com/${app.instagram}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    children={app.instagram}
                                />
                            );
                        }
                    },
                    renderCsv: (app) =>
                        app.instagram
                            ? `https://instagram.com/${app.instagram}`
                            : "",
                },
                {
                    id: "linkedin",
                    name: "LinkedIn",
                    render: (app) => {
                        if (app.linkedin) {
                            const { url, handle } = parseLinkedinUrl(
                                app.linkedin
                            );
                            return (
                                <a
                                    href={url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    children={handle}
                                />
                            );
                        }
                    },
                    renderCsv: (app) =>
                        app.linkedin ? parseLinkedinUrl(app.linkedin).url : "",
                },
                { id: "bio", name: "Bio" },
                {
                    id: "user",
                    name: "User",
                    render: (app) => {
                        return app.user
                            ? `${app.user.givenName} ${app.user.familyName}`
                            : "";
                    },
                },
                {
                    id: "action",
                    name: "Status",
                    render: (app) => {
                        const inx = processing.indexOf(app.id);
                        if (app.accepted === null) {
                            return (
                                <Button
                                    size="small"
                                    disabled={inx !== -1}
                                    startIcon={
                                        inx !== -1 ? (
                                            <Loader inline />
                                        ) : (
                                            <IconEdit />
                                        )
                                    }
                                    children="Respond"
                                    variant="outlined"
                                    onClick={(e) => onActionClick(e, app.id)}
                                />
                            );
                        } else if (app.accepted) {
                            return (
                                "Accepted: " +
                                moment(app.acceptedAt).format(df.date)
                            );
                        } else if (!app.accepted) {
                            return (
                                "Declined: " +
                                moment(app.acceptedAt).format(df.date)
                            );
                        }
                    },
                    renderCsv: (app) => {
                        if (app.accepted === true) {
                            return (
                                "Accepted: " +
                                moment(app.acceptedAt).format(df.date)
                            );
                        } else if (app.accepted === false) {
                            return (
                                "Declined: " +
                                moment(app.acceptedAt).format(df.date)
                            );
                        }
                        return "";
                    },
                },
            ];
        },
        // eslint-disable-next-line
        [processing]
    );

    const download = useSwallowEventCallback(async () => {
        setDownloading(true);
        const apps = await retrieveAll(ref.query);
        const csv = data2csv(cols, apps);
        await downloadCsv(csv, "pse-applications.csv");
        setDownloading(false);
    }, [query, cols]);

    const search = useCallback(
        () => loadApplications(),
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            loadApplications();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <div className="page page-w-loading">
                {loading && <Loader size={64} />}
                <NullForm className="toolbar">
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        onKeyDown={(e) => e.key === "Enter" && search()}
                        value={query}
                        label={query ? "" : "Search"}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        children="Search"
                        onClick={search}
                    />

                    <nav className="menu right">
                        <div className="menu-item">
                            <a
                                href="/#"
                                className="menu-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    loadApplications();
                                }}>
                                <IconRefresh className={refreshCls} />
                            </a>
                        </div>
                        <div className="menu-item">
                            <a
                                href="/#"
                                className="menu-link"
                                onClick={download}>
                                {downloading ? (
                                    <Loader size={24} />
                                ) : (
                                    <IconDownload />
                                )}
                            </a>
                        </div>
                    </nav>
                </NullForm>
                <Table
                    className="page-pse-applications-table"
                    variant="slim"
                    cols={cols}
                    rows={list}
                />
                <Menu
                    open={!!menuAnchor}
                    anchorEl={menuAnchor}
                    onClose={onMenuClose}>
                    <MenuItem onClick={onAcceptClickAuto}>
                        Accept (and create user automatically)
                    </MenuItem>
                    <MenuItem onClick={onAcceptClick}>
                        Accept (and create with custom access)
                    </MenuItem>
                    <MenuItem onClick={onAcceptNoUserClick}>
                        Accept (without creating user)
                    </MenuItem>
                    <MenuItem onClick={onEditClick}>Edit access</MenuItem>
                    <MenuItem onClick={onDeclineClick}>Decline</MenuItem>
                </Menu>
            </div>
            {
                <PSEApplicationDialog
                    editId={editApplication?.id}
                    onApplicationUpdated={onEdited}
                    onClose={onEditorClosed}
                />
            }
        </>
    );
}

export default PSEApplications;
